/**
 * Main application sass file that contains all the application styling
 */

// import bryntum theme
@import '~@bryntum/scheduler/scheduler.stockholm.css';

body,
html {
    margin         : 0;
    display        : flex;
    flex-direction : column;
    height         : 45vh;
    font-family    : Poppins, "Open Sans", Helvetica, Arial, sans-serif;
    font-size      : 14px;
}

#container {
    flex    : 1 1 100%;
    display : flex;
}

iframe#webpack-dev-server-client-overlay{display:none!important}
